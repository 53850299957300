
import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import moment from "moment";

type Task = {
  id?: number;
  date_from: Date;
  time_from: string;
  time_to: string;
  real_time_from: string;
  real_time_to: string;
  status_id: number;
  status?: {
    id: number;
    name: string;
  };
  order: {
    key_deposited?: string;
    description?: string;
  };
  driven_kilometers: string;
  expenses: string;
  notes: string;
  invoice_note: string;
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    datepicker_: () =>
      import("@/app/modules/_global/components/elements/DatePicker.vue"),
    timepicker_: () =>
      import("@/app/modules/_global/components/elements/TimePicker.vue")
  },
  filters: {
    task_duration(value, t) {
      const h = ((value * 60) / 60) | 0;
      const m = Math.round((value * 60) % 60);

      return `${h}${t} ${m}m`;
    }
  }
})
export default class TaskReportComponent extends Vue {
  @Model("input", { type: Object }) readonly task!: Task;
  @Prop(Boolean) readonly disabled?: boolean;
  @Prop(Object) readonly errors?: object;

  @Emit()
  input() {
    return this.formData;
  }

  @Watch("formData", { deep: true })
  onFormDataChanged() {
    this.input();
  }

  @Watch("errors", { deep: true })
  onErrorsChanged(errors) {
    this.localErrors = { ...errors };
  }

  valid: boolean = true as boolean;
  invoiceNoteActive = false as boolean;
  localErrors: object = {} as object;

  formData!: Task;

  rules = {
    required: Rules.required,
    date: Rules.date
  };

  constructor() {
    super();

    const task = (this.formData = { ...this.task });

    this.formData["real_time_from"] = task.real_time_from ?? task.time_from;
    this.formData["real_time_to"] = task.real_time_to ?? task.time_to;

    if (task.invoice_note) this.invoiceNoteActive = true;
  }

  get duration() {
    this.formData["real_duration"] = moment
      .duration(
        moment(this.formData["real_time_to"], "hh:mm").diff(
          moment(this.formData["real_time_from"], "hh:mm")
        )
      )
      .asHours();

    return this.formData["real_duration"];
  }

  statusIdIncluded(id: number[] = []): boolean {
    return id.includes(this.task?.status_id ?? 0);
  }
}
